.checkbox-wrapper{
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: center;
  width: 340px;
  position: relative;
  top: 0;
  min-height: 30px;
}

input[type='checkbox']{
  align-self: flex-start;
  width: 30px;
  height: 30px;
  margin-left: 8px;
  border: 0.5px solid var(--gray-strong);
  cursor: pointer;
}