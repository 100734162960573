.terms-page-content {
  width: 100%;
  height: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  overflow-x: hidden;
}

.terms-page-content h1 {
  margin-top: 20%;
  color: var(--purple-primary);
  font-size: 1.5rem;
  text-align: center;
}

.terms-page-content h2 {
  background-color: var(--purple-medium);
  font-size: 1rem;
  padding: 8px;
  color: white;
  margin: 16px 0;
  border-radius: 4px;
}

.terms-page-content main {
  color: var(--gray-strong);
  font-size: 1rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
}

.terms-page-content main section ul {
  list-style: decimal;
}


.terms-page-content main section h3 {
  font-size: 1rem;
  font-weight: bold;
}

@media(min-width: 1000px){
  .terms-page-content h1 {
    margin-top: 10%;
    font-size: 1.8rem;
  }
}