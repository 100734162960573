.publish-content{
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.publish-content main {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}