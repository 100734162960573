.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.container .description{
    color: var(--gray-strong);
    font-size: 1rem;
    align-self: center;
    margin: 10px;
    max-width: 80%;
}

@media(min-width: 900px){
    .container{
        align-items: start;
        width: auto;
    }
}