.default-button{
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 1.2rem;

  margin: 16px 0px;
  padding: 16px;
  border-radius: 2px;
  width: 100%;

  display: flex;
  align-items: center;

  transition: 200ms;
  cursor: pointer;
  position: relative;

  background-color: var(--purple-medium);
  color: white;
  border: 1px solid var(--purple-medium);

  justify-content: center;

  border-radius: 12px;

  opacity: 0.8;
}

.default-button[disabled]{
  background-color: var(--gray-medium);
  border: 1px solid var(--gray-medium);
  opacity: 1;
}

.default-button:hover{
  opacity: 1;
}