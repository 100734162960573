.spinner-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.spinner-wrapper .spinner-title {
  color: var(--purple-primary);
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 16px;
}