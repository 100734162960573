#rating-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 600px;
}

#rating-form label{
    color: var(--gray-strong);
    font-weight: bold;

    font-size: 15px;
    text-align: center;

    margin-top: 20px;
}

#rating-form p{
    font-size: 0.9rem;
    color:black;
    margin: 16px;
    max-width: 600px;
}

@media(min-width: 900px){
    #rating-form textarea{
        width: 400px;
        height: 150px;
    }

    #rating-form textarea:focus{
        border-bottom: 2px solid #1976d2;
    }
    
}