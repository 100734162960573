@keyframes titleSlideIn{
    from{
        left: 50%;
        transform: translateX(-50%);
    }
    to{
        left: -100px;
    }
}

@keyframes mainFadeIn{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

.home-content{
    width: 100%;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
}

.home-content main{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    text-align: center;
}

.home-content #mainContent{
    animation-name: mainFadeIn;
    animation-duration: 2s;
}

.home-content main article .title{
    margin-bottom: 32px;
    font-size: 2rem;
    font-weight: 600;
    color: var(--gray-strong);

    animation-name: titleSlideIn;
    animation-duration: 2s;
}

.home-content main p{
    max-width: 500px;
    text-align: center;
    color: var(--gray-strong);
    font-size: 1.3rem;
}

.home-content main img{
    height: 220px;
    z-index: -1;
    opacity: 0.8;
}

.home-content main .buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    margin-top: 32px;
}

.home-content main .buttons .button{
    text-decoration: none;
    text-transform: capitalize;
    font-weight: bold;
    color: white;
    font-size: 1.2rem;

    margin: 8px;
    padding: 16px;
    border-radius: 2px;
    width: 100%;

    display: flex;
    align-items: center;

    transition: 200ms;

    position: relative;

    border-radius: 12px;

    opacity: 0.8;
}

.home-content main .buttons .button.save{
    color: var(--purple-medium);
    border: 1.5px solid var(--purple-medium);

    justify-content: center;
}

.home-content main .buttons .button.search{
    background-color: var(--purple-light);
    justify-content: space-between;
}

.home-content main .buttons .button.search p{
    color:white;
}

.home-content main .buttons .button:hover{
    opacity: 1;
}

@media(min-width: 1000px){
    .home-content main article .title{
        font-size: 3rem;
    }

    .home-content main{
        flex-direction: row;
    }

    .home-content main img{
        height: 350px;
    }

    .home-content main .buttons .button{
        padding: 24px;
    }
}