header{
    position: fixed;
    top: 0;
    width: 100%;
    min-height: 50px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    z-index: 999;
}

header .login{
    margin: 8px;
    font-weight: 600;
    color:var(--purple-light);
    text-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    transition: 200ms;
    font-size: 1rem;
    padding: 16px;
}

header .profile{
    display: flex;
    align-items: center;
    justify-self: flex-start;

    font-weight: 600;
    color:var(--purple-light);
    text-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    transition: 200ms;
    font-size: 1rem;
    text-align: end;
    margin: 0 8px 8px 0;

    cursor: pointer;
}

header .profile svg{
    margin-left: 8px;
}

header .options-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    position: absolute;
    right: 8px;
    top: 65px;

    background-color: white;
    border-radius: 8px;
    padding: 0;

    box-shadow: 0 8px 24px rgba(140,149,159, 0.2);

    z-index: 100;
}

header .options-wrapper a , header .options-wrapper p{
    color: var(--gray-strong);
    font-weight: 500;
    font-size: 0.9rem;
    text-decoration: none;
    padding: 8px 14px;
    width: 100%;
}

header .options-wrapper a:hover, header .options-wrapper p:hover{
    background-color: var(--purple-light);
}

header .logo{
    font-size: 3rem;
    margin: 8px;
    color: var(--purple-light);
}

header > div{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
}

@media(min-width: 900px){
    header .login{
        font-size: 1.3rem;
    }

    header .logo{
        margin: 16px;
    }

    header .profile{
        font-size: 1.3rem;
    }
}