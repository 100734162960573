#modal-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 12px;
}

#modal-container #modal-content {
  background-color: #fff;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 20%;
  min-height: 20%;
  padding: 32px;
}