.help-content{
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.help-content main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
}

.help-content main h3{
    align-self: center;
    color: var(--purple-primary);
    font-size: 25px;
    text-align: center;
}

.help-content main ol{
    margin: 15px;
}

.help-content main ol li{
    align-items: center;
    align-self: center;
    max-width: 350px;
    margin: 5px;
    color: black;
    padding: 5px;
}

.help-content main ol li h6{
    font-size: 15px;
    max-width: 320px;
    font-weight: 600;
}

.help-content main ol li img{
    width: 320px;
    height: 200px;
    border: solid 1px #c4c4c4;
    border-radius: 10px;
}

@media(min-width: 900px){
    .help-content main ol li{
        max-width: none;
    }
    .help-content main ol li h6{
        font-size: 18px;
        max-width: 500px;
        font-weight: 600;
    }
    
    .help-content main ol li img{
        width: 600px;
        height: 300px;
    }
}