.plugin-content{
  height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.plugin-content main{
  position: relative;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  padding: 64px 0;
}

.plugin-content main .description {
  font-size: 1.2rem;
  color: var(--gray-strong);
  margin: 32px 0;
  max-width: 100%;
  font-weight: 500;
}

.plugin-content main .sub-description {
  font-size: 1rem;
  color: var(--gray-strong);
  margin: 32px 0;
  max-width: 90%;
  font-weight: 400;
}