*:focus {
    outline: none;
}

.search-content{
    width: 100%;
    height: 100%;
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
}

.search-header{
    background:#fff;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    min-height: 120px;
}

.search-header img{
    width: 30px;
    height: 30px;
}

.search-header .goBack{
    position: fixed;
    top: 20px;
    left: 10px;
    display: none;
    color: var(--purple-primary);
    font-size: 30px;
}

.search-header h1{
    font-weight: 200;
    font-size: 25px;
    line-height: 25px;
    text-align: center;
    color: var(--purple-primary);
}

.search-header form{
    margin: 10px;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.search-header .disable{
    display: none;
}

.search-header .enable{
    margin: 10px 0 0 0;
}

.search-header form input{
    border-radius: 2px;
    width: 320px;
    height: 40px;
    border: none;
    border-bottom: 0.5px solid #c4c4c4;

    padding: 5px;
}

.search-header form button{
    border-radius: 10px;
    border: none;
    height: 40px;
    width: 100%;
    margin-top: 5px;
    background-color: var(--purple-primary);
    cursor: pointer;
    color: #FFF;
    opacity: 0.7;
}

.search-header form button:hover{
    opacity: 1;
}

.search-header form button:focus{
    opacity: 1;
}

.search-content main{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-grow: 1;
}

.search-content header .filter{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.search-content header .filter button{
    width: 30px;
    height: 30px;
    cursor: pointer;
    background: transparent;
    border: 0;
    border-radius: 8px;
    color: var(--purple-primary);
}

.search-content header .filter form {
    position: absolute;
    top: 140px;
    right: 80px;
    background-color: white;
    border-radius: 8px;
    padding: 12px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.search-content header .filter .enabled{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
}

.search-content header .filter .disabled{
    display: none;
}

.search-content header .filter .enabled button{
    width: 100%;
    height: 40px;
    color: white;
    background-color: var(--purple-primary);
    opacity: 0.8;
}

.search-content main .results h2{
    font-weight: 300;
    color: var(--gray-strong);
    margin: 20px;
    text-align: center;
}

@media(min-width: 900px){
    .search-header .goBack{
        display: block;
    }

    .search-header form button{
        border-radius: 0 10px 10px 0;
        width: 50px;
        margin: 0;
    }

    .search-header img{
        width: 35px;
        height: 35px;
    }

    .search-header form{
        flex-direction: row;
        flex-wrap: wrap;
        max-width: 680px;

        justify-content: center;
    }

    .search-header form input{
        width: 550px;
    }

    .search-header form .formButtons{
        margin: 0 0 0 10px;
    }

    .search-content main .results .result-container{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    .search-content header .filter .enabled button:hover{
        opacity: 1;
        transition: 500ms;
    }

    .search-content header .filter form.enabled {
        position: absolute;
        border-radius: 12px 0 12px 12px;
        top: 120px;
        right: 32px;
    }
}